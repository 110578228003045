.tableInputs{
    margin-top: 3%;
    text-align: right;
    width: 81%;
}

.sizetd1{
    width: 100%;
}

.sizetd2{
    width: 20%;
}

.sizetd3{
    width: 14.5%;
}

.sizetd4{
    width: 22%;
}

:not(.mod-fg-field) > .form-control-sm{
    margin-left: 5px;
}
:not(.mod-fg-field) > .form-select-sm{
    margin-left: 5px;
}


.contTypeReport {
    padding: 50px; 
}

.fieldset{
    margin-top:20px;
    height:100%;
    width:80%;
    border:1px solid #ccc;
    border-radius: 10px;
}

.legend{
    width: 270px;
    font-size:18px;
    margin-top:-12px;
    margin-left:20px;
    background:white;
    padding-left: 30px;

}

.container2{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contentCheck{
    background: rgb(233, 232, 232);
    margin: 20px;
    margin-right: 10%;
    margin-left: 10%;
    border-radius: 4px;
    padding-left: 10px;
}

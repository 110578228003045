.container {
    margin: 20px;
}

.titleSearch {
    padding: 20px;
    background: #eeee;
}

.size-buscar {
    margin-right: 50px;
}

.table-scroll{
    overflow:scroll;
    height:50vh;
}

.table-scroll table {
    border-collapse: separate;
    border-spacing: 0;
}

.table-scroll table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
}

.table-scroll table thead th {
    background-color: #eeee;
}

.table-scroll table thead {
    background: #dddddd !important;
}

.btnCuenta button {
    width: 150px;
    margin-left: 30px;
}

.table table-hover {
    border: 1px solid;
}

.table table-hover tbody tr {
    cursor: pointer;
}

.tableSelected {
    background-color: #dddddd;
}

.btnCuenta button[disabled="true"]{
    cursor: not-allowed;  
}



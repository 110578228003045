/* Todo */

.App {
    text-align: center;
    margin-top: -1%;
    margin-bottom: 3%;
}
  
.header{
    width: 100%;
    text-align: left;
    padding: 1%;
    background-color: #003770;
    color: white;
    border-radius: 5px;
}

.header-light-grey {
    background-color: #eeee!important;
    color: black!important;
}

.btn-light-grey {
    background-color: #dddc;
    color: black;
}

.min-width-web-only {
    min-width: 1300px;
}

@media (max-width: 1350px) {
    .min-width-web-only {
        min-width: 90%;
    }
}

.btn-light-grey:hover, .btn-light-grey:focus-visible, .btn-light-grey:focus {
    background-color: #eee;
    color:black;
}

.header-right {
    float: right;
    width: 15%;
    margin-top: auto;
    margin-bottom: auto;
}

.header img {
    width: 100%;
}
  
.title{
    font-weight: bold;
    font-size: 18px;
}
.size1{
    width: 15%;
}
.size2{
    width: 35%;
}

.size4{
    width: 2%;
    text-align: center;
}

table { 
    border-collapse:collapse; 
}
  
.buttons{
    text-align: center;
    background-color: #003770 !important;
}
  
.bt2{
    margin-left: 2%;
}

.alert-danger{
    font-size: 12px;
}
.form-check{
    padding-right: 0%;
}

/*REPORTES*/

.tableInputs{
    margin-top: 3%;
    text-align: right;
    width: 80%;
}

.sizetd1{
    width: 50%;
}

.sizetd2{
    width: 20%;
}

.sizetd3{
    width: 30%;
}

:not(.mod-fg-field) > .form-control-sm{
    margin-left: 2%;
}

.contTypeReport {
    padding: 20px 50px!important; 
}

.fieldset{
    margin-top:20px;
    height:100%;
    width:80%;
    border:1px solid #ccc;
    border-radius: 10px;
}

.legend{
    width: 270px;
    font-size:18px;
    margin-top:-12px;
    margin-left:20px;
    background:white;
    padding-left: 30px;

}

.container2{
    display: flex;
    align-items: center;
    justify-content: center;
}

/*TABLAS*/

.container {
    margin: 20px;
}

.titleSearch {
    padding: 20px;
    background: #eeee;
}

.size-buscar {
    margin-right: 50px;
}

.table-scroll{
    overflow:scroll;
    height:50vh;
}

.PayLine thead tr th {
    border-top: 0px;
    border: 1px solid #ccc;
    background-color: #eeee;
}

.PayLine tbody button:hover{
    background-color: #c3c3c3;
}

.table-scroll table {
    border-collapse: separate;
    border-spacing: 0;
}

.table-scroll table thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
}

.table-scroll table thead th {
    background-color: #eeee;
}

.table-scroll table thead {
    background: #dddddd !important;
}

.btnCuenta button {
    width: 150px;
    margin-left: 30px;
}

.table-scroll .table-hover tbody tr {
    cursor: pointer;
}

.tableSelected {
    background-color: #dddddd;
    border: 0px;
    outline: none;
}

.table-payHeader {
    text-align: left;
    border: 1px solid;
}

.table-paybody {
    border: 1px solid #ccc;
}

.table-payHeader .payHeader{
    font-size: 18px;
    background-color: #eeee;
}
  
/*TABLAS MANTENIMIENTO*/

.contTableMaintenance {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*MODAL*/

.main-modal {
    max-width: 70%!important;
}

/*ALERT CONFIRM REACT*/

.react-confirm-alert-body {
    text-align: center !important;
    width: 100% !important;
}

.react-confirm-alert-button-group > button {
    font-size:medium !important;
    display: flex !important;
}

.react-confirm-alert-button-group  {
    justify-content:center !important;
}

.input-group-text:hover{
    background-color: #003770;
    color: white;
}

/* --- utils --- */

.bg-blue {
    background-color: #003770 !important;
}

.flex-1 {
    flex: 1;
}

.fw-medium {
    font-weight: 500;
}

.msg-error {
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
}

.bg-img-none {
    background-image: none !important;
}

.text-transparent {
    color: transparent;
}

.cursor-pointer {
    cursor: pointer;
}

.select-none {
    user-select: none;
}

.loading-spinner {
    border: 16px solid #fff;
    border-top: 16px solid #003770;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* xl > 1200 */
@media (min-width: 1200px) {
    .w-xl-75 {
        width: 75% !important;
    }
}

/* Dialog */
.dialog .modal-content {
    min-width: 25rem;
}

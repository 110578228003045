.receipt-search{
    --secondary-color: #eeee;
    background: white;
    width: 75vw;
    border-radius: 10px;
    padding: 3vh 0;
    margin: 5vh auto;
    box-shadow: 10px 10px 20px lightgrey;
    text-align: center;
}

.receipt-search input{
    height: 3vh;
}

.receipt-search *{
    font-size: 2vh;
}

.secondary{
    margin: 2vh 3.5vw !important;
    background: var(--secondary-color);
    border-radius: 5px;
    padding: 1vh 1vw !important;
}

.searchFields{
    display: flex;
    justify-content: space-between;
}

/* .field > *{
    display: block;
    padding: 0px 5px;
}

.field > input{
     width: 100% !important;
} */

.field {
    width: 100%;
    margin-inline: 1%;
}

.motive-field{
    text-align: center;
    margin: 1vh auto;
    width: 75%;
}

.motive-field >*{
    display: block;
    width: 100%;
}


.botones-search-receipt{
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    display: flex;
    padding: 0px 10%;
    justify-content: space-between;
}

.instructions{
    display: block;
    margin: 2vh 5vw 0;
}

.botones-search-receipt > button{
    margin: 0px 20% 0px 20%;
}

.receipt-search > select:focus{
    background-color: darkgray;
    border-radius: 5px;
}

.receipt-search > p {
    position: relative;
    bottom: 0.5vh;
    left: 0;
    right: 0;
    margin: 0px;
    padding: 0 10%;
    text-align: center;
}


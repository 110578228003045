.navbar-title {
    font-size: 24px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-align: center;
}

.navbar-links {
    text-align: center;
    background-color: lightgray;
}

.navbar-links .link {
    color: #003770;
}

.navbar-links .link:hover {
    border-color: #003770;
    background-color: #003770;
    color: #fff;
}

.navbar-links .link:focus {
    outline: 0;
    box-shadow: none;
}

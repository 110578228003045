.PopUp{
    width: 40vw;
    height: fit-content;
    border-radius: 25px;
    box-shadow: 0px 0px 5px #002A4C;
    background-color: lightgray;
    padding: 3vh 0vh;
}

.modal-dialog {
    max-width: fit-content;
}

.modal-content:has(.PopUp) {
    background-color: transparent;
    border-color: transparent;
}

.PopUp > *{
    margin-top: 20px;
}

.PopUp > h3{
    background-color: white;
    min-height: 80px;
    text-align: center;
    vertical-align: middle;
    position: relative;
    width: 100%;
    padding: 16px 0;
}
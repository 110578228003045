.payment-success-main-container {
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 300px;
}

.centered-text {
    text-align: center;
}

.payment-succes-content {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 50%;
}

@media (max-width: 715px) {
    .payment-succes-content {
        display: flex;
        flex-direction: column;
        padding: 5px;
        width: 90%;
    }
}

.payment-succes-content>* {
    font-size: 1.5rem;
}

.payment-succes-content>*:first-child {
    font-size: 2rem;
}

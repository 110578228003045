.page-container {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.page-main {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.page-main .content {
    display: flex;
    flex-direction: column;
    padding: 1.75rem 2.5rem;
}
.page-main .section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}
.page-main .section-title .content-title {
    font-size: calc(1.3rem + 0.6vw);
}

div.fade-pane {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .4);
    z-index: 500;
    border-radius: .25rem;
}

div.fade-pane > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.fade-pane .close-message{
    width: 40vw;
    border-radius: 25px;
    box-shadow: 0px 0px 5px black;
    background-color: lightgray;
    padding: 3vh 0vh;
}

.fade-pane .close-message > *{
    margin-top: 20px;
}

.fade-pane .close-message > h3{
    background-color: white;
    min-height: 80px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    padding: 16px 16px;
}

.karex {
    background: white;
    width: 80%;
    border-radius: 5px;
    box-shadow: 0 0 5px lightgrey;
    height: fit-content;
    padding: 4vh 1vw;
    margin: auto;
}

.kardex-valores>tbody>tr>td {
    display: inline-block;
    overflow: auto;
    max-height: 40px;
    min-height: 40px;
}

.message {
    text-align: center;
    margin: auto;
    border-radius: 5px;
    width: 20vw;
    position: fixed;
    top: 15vh;
    right: 2vw;
}

.success {
    background: green;
    color: white;
}

.error {
    background: red;
    color: white;
}

@keyframes fadeIn {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.fade1 {
    animation: fadeIn ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.kardex-valores>thead>tr>th {
    display: inline-block;
    line-height: 80px;
    height: 80px;
}

.karex input {
    height: 3vh;
}

.karex * {
    font-size: 2vh;
}

.kardex-valores {
    width: 100%;
}

.kardex-valores>thead {
    width: 100%;
    background: #eee;
    display: table;
}

.kardex-valores>tbody {
    width: 100%;
    display: block;
}

.kardex-tr {
    width: 100%;
    display: table;
}

.kardex-tr>th, .kardex-tr>td {
    vertical-align: middle;
    border-collapse: collapse;
    border-left: 1px solid black;
    border-top: 1px solid black;
    text-align: center;
}

.kardex-tr>td:last-child, .kardex-tr>th:last-child {
    border-right: 1px solid black;
}

.kardex-tr:last-child{
    border-bottom: 1px solid black;
}

.kardex-submit {
    display: block !important;
    margin: auto;
    margin-top: 20px;
}
.modular-advanced-select {
  position: relative;
  display: inline-block;
}
.modular-advanced-select .mod-as-field {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0.275rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
}
.modular-advanced-select .mod-as-field:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.modular-advanced-select .mod-as-field .mod-asf-label {
  width: 100%;
  max-width: 100%;
  min-height: 1em;
  flex: 1 0 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modular-advanced-select .mod-as-field .mod-asf-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  flex: 0 0 auto;
  margin-left: 0.375rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.modular-advanced-select .mod-as-field .mod-asf-clear:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.mod-as-menu {
  max-height: 15rem;
  min-width: 10rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.mod-as-menu .mod-asm-header {
  border-bottom: 1px solid #ced4da;
}
.mod-as-menu .mod-asm-header .mod-as-filter {
  width: 100%;
  padding: 0.5rem 0.75rem 0.5rem 2.25rem;
  border: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/></svg>');
  background-repeat: no-repeat;
  background-position: left 0.75rem center;
  background-size: 1rem 0.75rem;
}
.mod-as-menu .mod-asm-header .mod-as-filter:focus {
  outline: 0;
}
.mod-as-menu .mod-asm-options {
  width: 100%;
  padding: 0.5rem 0;
  overflow-y: auto;
}
.mod-as-menu .mod-asm-options .mod-asm-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 1rem;
}
.mod-as-menu .mod-asm-options .mod-asm-option .mod-asmo-label {
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.mod-as-menu .mod-asm-options .mod-asm-option:hover, .mod-as-menu .mod-asm-options .mod-asm-option.hovered {
  outline: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.mod-as-menu .mod-asm-options .mod-asm-option.selected {
  color: #0d6efd;
}
.mod-as-menu .mod-asm-options .mod-asm-option .mod-asmo-checkbox {
  display: flex;
  margin-right: 0.5rem;
}
.mod-as-menu .mod-asm-options .mod-asm-empty-option {
  padding: 0.25rem 1rem;
  user-select: none;
  font-style: italic;
}
@media (min-width: 480px) {
  .mod-as-menu {
    max-width: 480px;
  }
}

.modular-button .mod-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.modular-button .mod-button-content .mod-button-content-spinner {
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modular-button .mod-button-content .mod-button-spinner {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.2em solid currentcolor;
  border-right-color: transparent;
  animation: 0.75s linear infinite spin;
}

.modular-button-icon {
  width: 1.5em;
  height: 1.5em;
}
.modular-button-icon .mod-button-icon-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modular-button-icon .mod-button-icon-content .button-icon-spinner {
  display: inline-block;
  width: 0.825em;
  height: 0.825em;
  border-radius: 50%;
  border: 0.2em solid currentcolor;
  border-right-color: transparent;
  animation: 0.75s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.modular-checkbox {
  --checkbox-border-color: #dee2e6;
  appearance: none;
  width: 1em;
  height: 1em;
  border: 1px solid var(--checkbox-border-color);
  border-radius: 0.25em;
  background-color: var(--checkbox-bg-color);
  background-image: var(--checkbox-bg-image);
  background-size: 1em;
  background-position: center;
  background-repeat: no-repeat;
}
.modular-checkbox:checked {
  --checkbox-bg-color: #0d6efd;
  --checkbox-border-color: #0d6efd;
}
.modular-checkbox.checked {
  --checkbox-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 7.75L7.75 10.5L11.75 5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.modular-checkbox.unchecked {
  --checkbox-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L8 8M11 11L8 8M11 5L8 8M8 8L5 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.modular-checkbox:disabled, .modular-checkbox.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.modular-checkbox:focus, .modular-checkbox.focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.modular-confirm .mod-confirm-header {
  padding: 0.75rem 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
}
.modular-confirm .mod-confirm-body {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.modular-confirm .mod-confirm-footer {
  display: flex;
  justify-content: right;
  padding: 0.75rem 1rem;
}
.modular-confirm .mod-confirm-footer > * {
  min-width: 5rem;
  text-wrap: nowrap;
}
.modular-confirm .mod-confirm-footer > *:not(:last-child) {
  margin-right: 0.5rem;
}
.modular-confirm .mod-confirm-footer > * > .mod-cfo-icon {
  display: inline;
  margin-right: 0.25rem;
}

.modular-datatable {
  --datatable-row-selected-color: #0d6efd;
  --datatable-row-disabled-color: #495057;
  --datatable-row-hover-bg-color: #e9ecef;
  --datatable-row-focus-shadow: rgba(13, 110, 253, 0.25);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow: hidden;
}
.modular-datatable .dt-wrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.modular-datatable .dt-wrapper > table {
  width: 100%;
  border-collapse: collapse;
}
.modular-datatable .dt-wrapper th.pos-start {
  text-align: left;
}
.modular-datatable .dt-wrapper th.pos-end {
  text-align: right;
}
.modular-datatable .dt-wrapper th.pos-center {
  text-align: center;
}
.modular-datatable .dt-wrapper.dth-sticky {
  background-color: inherit;
}
.modular-datatable .dt-wrapper.dth-sticky > table {
  border-collapse: separate;
  border-spacing: 0;
  background-color: inherit;
}
.modular-datatable .dt-wrapper.dth-sticky > table thead,
.modular-datatable .dt-wrapper.dth-sticky > table tbody,
.modular-datatable .dt-wrapper.dth-sticky > table tfoot {
  background-color: inherit;
}
.modular-datatable .dt-wrapper.dth-sticky > table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.modular-datatable .dt-wrapper.dth-sticky > table .dtr-subheader {
  position: sticky;
  z-index: 1;
  background-color: inherit;
}
.modular-datatable .dt-wrapper .dth-sortable {
  display: inline-flex;
  gap: 0 0.25rem;
  cursor: pointer;
}
.modular-datatable .dt-wrapper .dth-sortable.sorted {
  color: #0d6efd;
}
.modular-datatable .dt-wrapper .dth-sortable .dth-sort-icon {
  padding: 0.25em;
  line-height: 1em;
  border-radius: 50%;
}
.modular-datatable .dt-wrapper .dth-sortable .dth-sort-icon svg {
  width: 1em;
  height: 1em;
}
.modular-datatable .dt-wrapper .dth-selector,
.modular-datatable .dt-wrapper .dtr-selector {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
}
.modular-datatable .dt-wrapper .dtr-selectable {
  cursor: pointer;
}
.modular-datatable .dt-wrapper .dtr-selectable:hover {
  background-color: var(--datatable-row-hover-bg-color);
}
.modular-datatable .dt-wrapper .dtr-selectable:focus {
  outline: 0;
  box-shadow: inset 0 0 0 0.25rem var(--datatable-row-focus-shadow);
}
.modular-datatable .dt-wrapper .dtr-selected > * {
  color: var(--datatable-row-selected-color);
}
.modular-datatable .dt-wrapper .dtr-disabled > * {
  color: var(--datatable-row-disabled-color);
}
.modular-datatable .dt-wrapper .dtr-subheader {
  font-weight: bold;
}
.modular-datatable .dt-wrapper .dtr-subheader > * {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.modular-datatable .dt-wrapper .dtr-msg-on-empty {
  text-align: center;
  font-weight: 500;
}
.modular-datatable .dt-wrapper .dtr-cell-skeleton {
  width: 100%;
  height: 1.5em;
  border-radius: 0.25em;
  background: #ccc;
  animation: pulse 1.5s infinite ease-in-out;
}
.modular-datatable .dt-wrapper .dtr-check-skeleton {
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  background: #ccc;
  animation: pulse 1.5s infinite ease-in-out;
}
.modular-datatable .dt-wrapper .dtr-radio-skeleton {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #ccc;
  animation: pulse 1.5s infinite ease-in-out;
}
@media (max-width: 639.98px) {
  .modular-datatable .dt-wrapper {
    overflow-x: auto;
  }
}
@media (max-width: 639.98px) {
  .modular-datatable .dt-wrapper.responsive-sm {
    overflow-x: auto;
  }
}
@media (max-width: 767.98px) {
  .modular-datatable .dt-wrapper.responsive-md {
    overflow-x: auto;
  }
}
@media (max-width: 1023.98px) {
  .modular-datatable .dt-wrapper.responsive-lg {
    overflow-x: auto;
  }
}
@media (max-width: 1279.98px) {
  .modular-datatable .dt-wrapper.responsive-xl {
    overflow-x: auto;
  }
}
.modular-datatable .dts-detail {
  font-size: 0.75rem;
  text-align: right;
  white-space: pre;
}

@keyframes pulse {
  0% {
    background-color: #ccc;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #ccc;
  }
}
.modular-editable-select {
  position: relative;
  display: inline-block;
}
.modular-editable-select .mod-es-field {
  width: 100%;
}

.mod-es-menu {
  max-height: 15rem;
  overflow-y: auto;
  display: block;
  z-index: 1000;
  padding: 0.5rem 0;
  min-width: 10rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.mod-es-menu .mod-esm-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 1rem;
}
.mod-es-menu .mod-esm-option .mod-esmo-label {
  max-width: 100%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.mod-es-menu .mod-esm-option:hover, .mod-es-menu .mod-esm-option.hovered {
  outline: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.mod-es-menu .mod-esm-option.selected {
  color: #0d6efd;
}
.mod-es-menu .mod-esm-empty-option {
  padding: 0.25rem 1rem;
  user-select: none;
  font-style: italic;
}
@media (min-width: 480px) {
  .mod-es-menu {
    max-width: 480px;
  }
}

.modular-field-group {
  margin-bottom: 1rem;
}
.modular-field-group > .mod-fg-label {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
  order: 0;
}
.modular-field-group > .mod-fg-label.hide {
  display: none;
}
.modular-field-group > .mod-fg-field {
  width: 100%;
  order: 1;
}
.modular-field-group > .mod-fg-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--field-group-feedback-color);
  order: 2;
}
.modular-field-group > .mod-fg-feedback.show {
  display: block;
}
.modular-field-group > .mod-fg-feedback.mod-fgf-error {
  --field-group-feedback-color: #dc3545;
}
.modular-field-group > .mod-fg-feedback.mod-fgf-info {
  --field-group-feedback-color: #0d6efd;
}
.modular-field-group > .mod-fg-feedback.mod-fgf-success {
  --field-group-feedback-color: #198754;
}
.modular-field-group > .mod-fg-feedback.mod-fgf-warning {
  --field-group-feedback-color: #dda600;
}
.modular-field-group > .mod-fg-feedback.mod-fgf-neutral {
  --field-group-feedback-color: #6c757d;
}
.modular-field-group.inline {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.modular-field-group.inline > .mod-fg-label {
  margin-right: 0.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  flex: 0 0 auto;
  width: auto;
}
.modular-field-group.inline > .mod-fg-field {
  flex: 1 0 0%;
}
.modular-field-group.inline > .mod-fg-feedback {
  text-align: center;
  flex: 0 0 auto;
}
@media (min-width: 640px) {
  .modular-field-group.inline-sm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .modular-field-group.inline-sm > .mod-fg-label {
    margin-right: 0.5rem;
    margin-bottom: 0;
    white-space: nowrap;
    flex: 0 0 auto;
    width: auto;
  }
  .modular-field-group.inline-sm > .mod-fg-field {
    flex: 1 0 0%;
  }
  .modular-field-group.inline-sm > .mod-fg-feedback {
    text-align: center;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) {
  .modular-field-group.inline-md {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .modular-field-group.inline-md > .mod-fg-label {
    margin-right: 0.5rem;
    margin-bottom: 0;
    white-space: nowrap;
    flex: 0 0 auto;
    width: auto;
  }
  .modular-field-group.inline-md > .mod-fg-field {
    flex: 1 0 0%;
  }
  .modular-field-group.inline-md > .mod-fg-feedback {
    text-align: center;
    flex: 0 0 auto;
  }
}
@media (min-width: 1024px) {
  .modular-field-group.inline-lg {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .modular-field-group.inline-lg > .mod-fg-label {
    margin-right: 0.5rem;
    margin-bottom: 0;
    white-space: nowrap;
    flex: 0 0 auto;
    width: auto;
  }
  .modular-field-group.inline-lg > .mod-fg-field {
    flex: 1 0 0%;
  }
  .modular-field-group.inline-lg > .mod-fg-feedback {
    text-align: center;
    flex: 0 0 auto;
  }
}
@media (min-width: 1280px) {
  .modular-field-group.inline-xl {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .modular-field-group.inline-xl > .mod-fg-label {
    margin-right: 0.5rem;
    margin-bottom: 0;
    white-space: nowrap;
    flex: 0 0 auto;
    width: auto;
  }
  .modular-field-group.inline-xl > .mod-fg-field {
    flex: 1 0 0%;
  }
  .modular-field-group.inline-xl > .mod-fg-feedback {
    text-align: center;
    flex: 0 0 auto;
  }
}

.modular-input-icon {
  position: relative;
  display: inline-block;
}
.modular-input-icon .mod-ii-start-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.modular-input-icon .mod-ii-end-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modular-modal-backdrop {
  --modal-backdrop-opacity: 0;
  z-index: 1020;
  background-color: #000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  opacity: var(--modal-backdrop-opacity);
  transition: opacity 0.15s linear;
}
.modular-modal-backdrop.show {
  --modal-backdrop-opacity: 0.5;
}

.modular-modal-container {
  z-index: 1020;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  padding: 1rem;
  transition: opacity 0.15s linear;
  overflow-y: auto;
}
.modular-modal-container:not(.show) {
  opacity: 0;
}
.modular-modal-container .modular-modal-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
}

.modular-pagination {
  --pagination-button-bg: #0d6efd;
  --pagination-button-color: #fff;
  --pagination-button-hover-bg: #0d6efd;
  --pagination-button-hover-color: #fff;
  --pagination-button-active-bg: #0d6efd;
  --pagination-button-active-color: #fff;
}
.modular-pagination .mod-p-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem 2rem;
}
.modular-pagination .mod-p-wrapper .mod-p-extra {
  margin-right: auto;
}
.modular-pagination .mod-p-wrapper .mod-p-controls {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-weight: 500;
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.25em;
  height: 2.25em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid #dee2e6;
  border-radius: 0.375em;
  background-color: inherit;
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-button:focus {
  outline: 0;
  color: var(--pagination-button-focus-color);
  background-color: var(--pagination-button-focus-bg);
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-button:hover {
  color: var(--pagination-button-hover-color);
  background-color: var(--pagination-button-hover-bg);
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-button.active {
  pointer-events: none;
  color: var(--pagination-button-active-color);
  background-color: var(--pagination-button-active-bg);
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-input {
  width: 3rem;
  text-align: center;
  padding: 0.375rem 0.25rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: inherit;
  margin: 0 0.25rem;
  line-height: 1.25;
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pp-input:focus {
  outline: 0;
}
.modular-pagination .mod-p-wrapper .mod-p-controls .mod-pps-selector {
  padding: 0.375rem 0.25rem 0.375rem 0.5rem;
  cursor: pointer;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: inherit;
  margin-left: 0.25rem;
}
.modular-pagination.responsive .mod-p-wrapper {
  flex-direction: column-reverse;
}
@media (max-width: 639.98px) {
  .modular-pagination .mod-p-wrapper {
    flex-direction: column-reverse;
  }
}
@media (max-width: 639.98px) {
  .modular-pagination.responsive-sm .mod-p-wrapper {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767.98px) {
  .modular-pagination.responsive-md .mod-p-wrapper {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1023.98px) {
  .modular-pagination.responsive-lg .mod-p-wrapper {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1279.98px) {
  .modular-pagination.responsive-xl .mod-p-wrapper {
    flex-direction: column-reverse;
  }
}

.modular-picklist {
  --picklist-border-color: #dee2e6;
  --picklist-border-radius: .375rem;
  --picklist-button-bg: #0d6efd;
  --picklist-button-color: #fff;
  --picklist-option-hover-bg: #0d6efd;
  --picklist-option-hover-color: #fff;
  --picklist-option-active-color: #0d6efd;
  --picklist-button-border-color: #0d6efd;
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--picklist-border-color);
  border-radius: var(--picklist-border-radius);
}
.modular-picklist .pl-side {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.modular-picklist .pl-side .pls-header {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid var(--picklist-border-color);
  border-bottom: 0 none;
  border-top-right-radius: var(--picklist-border-radius);
  border-top-left-radius: var(--picklist-border-radius);
}
.modular-picklist .pl-side .pls-filter {
  padding: 0.5rem 1rem;
  border: 1px solid var(--picklist-border-color);
  border-bottom: 0 none;
}
.modular-picklist .pl-side .pls-filter:first-child {
  border-top-right-radius: var(--picklist-border-radius);
  border-top-left-radius: var(--picklist-border-radius);
}
.modular-picklist .pl-side .pls-filter > * {
  width: 100%;
}
.modular-picklist .pl-side .pls-list {
  height: 100%;
  padding: 0.75rem 0;
  overflow-y: auto;
  border: 1px solid var(--picklist-border-color);
  border-bottom-right-radius: var(--picklist-border-radius);
  border-bottom-left-radius: var(--picklist-border-radius);
}
.modular-picklist .pl-side .pls-list:first-child {
  border-top-right-radius: var(--picklist-border-radius);
  border-top-left-radius: var(--picklist-border-radius);
}
.modular-picklist .pl-side .pls-list .pls-option {
  padding: 0.375rem 1rem;
  cursor: pointer;
}
.modular-picklist .pl-side .pls-list .pls-option.active {
  color: var(--picklist-option-active-color);
  background-color: var(--picklist-option-active-bg);
}
.modular-picklist .pl-side .pls-list .pls-option:hover {
  color: var(--picklist-option-hover-color);
  background-color: var(--picklist-option-hover-bg);
}
.modular-picklist .pl-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}
.modular-picklist .pl-controls .plc-button {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--picklist-button-bg);
  color: var(--picklist-button-color);
  border-radius: 0.375rem;
  border: 1px solid var(--picklist-button-border-color);
}
.modular-picklist .pl-controls .plc-button.plc-button-add::before, .modular-picklist .pl-controls .plc-button.plc-button-add-all::before, .modular-picklist .pl-controls .plc-button.plc-button-remove::before, .modular-picklist .pl-controls .plc-button.plc-button-remove-all::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background-image: var(--picklist-control-button-bg-image);
  background-size: 1rem;
  background-position: center;
  background-repeat: no-repeat;
}
.modular-picklist .pl-controls .plc-button.plc-button-add::before {
  --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 2L11 8L5 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.modular-picklist .pl-controls .plc-button.plc-button-add-all::before {
  --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 2L9 8L3 14M7 2L13 8L7 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.modular-picklist .pl-controls .plc-button.plc-button-remove::before {
  --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 2L5 8L11 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.modular-picklist .pl-controls .plc-button.plc-button-remove-all::before {
  --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 2L3 8L9 14M13 2L7 8L13 14" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
@media (max-width: 639.98px) {
  .modular-picklist {
    flex-direction: column;
    overflow-y: auto;
  }
  .modular-picklist .pl-side .pls-list {
    min-height: 7.5rem;
  }
  .modular-picklist .pl-controls {
    flex-direction: row;
  }
  .modular-picklist .pl-controls .plc-button.plc-button-add::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L8 11L14 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist .pl-controls .plc-button.plc-button-add-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3L8 9L14 3M14 7L8 13L2 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist .pl-controls .plc-button.plc-button-remove::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L8 5L14 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist .pl-controls .plc-button.plc-button-remove-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L8 3L14 9M14 13L8 7L2 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
@media (max-width: 639.98px) {
  .modular-picklist.responsive-sm {
    flex-direction: column;
    overflow-y: auto;
  }
  .modular-picklist.responsive-sm .pl-side .pls-list {
    min-height: 7.5rem;
  }
  .modular-picklist.responsive-sm .pl-controls {
    flex-direction: row;
  }
  .modular-picklist.responsive-sm .pl-controls .plc-button.plc-button-add::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L8 11L14 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-sm .pl-controls .plc-button.plc-button-add-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3L8 9L14 3M14 7L8 13L2 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-sm .pl-controls .plc-button.plc-button-remove::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L8 5L14 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-sm .pl-controls .plc-button.plc-button-remove-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L8 3L14 9M14 13L8 7L2 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
@media (max-width: 767.98px) {
  .modular-picklist.responsive-md {
    flex-direction: column;
    overflow-y: auto;
  }
  .modular-picklist.responsive-md .pl-side .pls-list {
    min-height: 7.5rem;
  }
  .modular-picklist.responsive-md .pl-controls {
    flex-direction: row;
  }
  .modular-picklist.responsive-md .pl-controls .plc-button.plc-button-add::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L8 11L14 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-md .pl-controls .plc-button.plc-button-add-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3L8 9L14 3M14 7L8 13L2 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-md .pl-controls .plc-button.plc-button-remove::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L8 5L14 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-md .pl-controls .plc-button.plc-button-remove-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L8 3L14 9M14 13L8 7L2 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
@media (max-width: 1023.98px) {
  .modular-picklist.responsive-lg {
    flex-direction: column;
    overflow-y: auto;
  }
  .modular-picklist.responsive-lg .pl-side .pls-list {
    min-height: 7.5rem;
  }
  .modular-picklist.responsive-lg .pl-controls {
    flex-direction: row;
  }
  .modular-picklist.responsive-lg .pl-controls .plc-button.plc-button-add::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L8 11L14 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-lg .pl-controls .plc-button.plc-button-add-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3L8 9L14 3M14 7L8 13L2 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-lg .pl-controls .plc-button.plc-button-remove::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L8 5L14 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-lg .pl-controls .plc-button.plc-button-remove-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L8 3L14 9M14 13L8 7L2 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}
@media (max-width: 1279.98px) {
  .modular-picklist.responsive-xl {
    flex-direction: column;
    overflow-y: auto;
  }
  .modular-picklist.responsive-xl .pl-side .pls-list {
    min-height: 7.5rem;
  }
  .modular-picklist.responsive-xl .pl-controls {
    flex-direction: row;
  }
  .modular-picklist.responsive-xl .pl-controls .plc-button.plc-button-add::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5L8 11L14 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-xl .pl-controls .plc-button.plc-button-add-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3L8 9L14 3M14 7L8 13L2 7" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-xl .pl-controls .plc-button.plc-button-remove::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 11L8 5L14 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
  .modular-picklist.responsive-xl .pl-controls .plc-button.plc-button-remove-all::before {
    --picklist-control-button-bg-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 9L8 3L14 9M14 13L8 7L2 13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }
}

/* Toast */
.modular-toaster {
  position: fixed;
  width: 25rem;
  z-index: 1090;
  /* Position */
  /* Transition */
}
.modular-toaster .modular-toast {
  margin: 0 0 1rem 0;
  box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  background: var(--toast-bg);
  border-style: solid;
  border-color: var(--toast-border-color);
  border-width: var(--toast-border-width);
  color: var(--toast-color);
}
.modular-toaster .modular-toast .toast-content {
  display: flex;
  align-items: center;
  padding: 0.75rem;
}
.modular-toaster .modular-toast .toast-content .toast-icon {
  line-height: 1;
  font-size: 2rem;
  color: var(--toast-icon-color);
}
.modular-toaster .modular-toast .toast-content .toast-message {
  flex: 1 1 auto;
  margin: 0 0 0 1rem;
}
.modular-toaster .modular-toast .toast-content .toast-message .toast-title {
  font-weight: 700;
}
.modular-toaster .modular-toast .toast-content .toast-message .toast-detail {
  margin: 0.375rem 0 0 0;
}
.modular-toaster .modular-toast .toast-content .toast-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  color: var(--toast-close-button-color);
}
.modular-toaster .modular-toast .toast-content .toast-close-button:hover {
  background: rgba(255, 255, 255, 0.3);
}
.modular-toaster .modular-toast .toast-content .toast-close-button:focus {
  outline: 0 none;
  box-shadow: 0 0 0 0.25rem var(--toast-close-button-shadow-color);
}
.modular-toaster.top-right {
  top: 1.5rem;
  right: 1.5rem;
}
.modular-toaster.top-left {
  top: 1.5rem;
  left: 1.5rem;
}
.modular-toaster.bottom-left {
  bottom: 1.5rem;
  left: 1.5rem;
}
.modular-toaster.bottom-right {
  bottom: 1.5rem;
  right: 1.5rem;
}
.modular-toaster.top-center {
  top: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.modular-toaster.bottom-center {
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
}
.modular-toaster .toast-enter {
  opacity: 0;
  transform: translateY(50%);
}
.modular-toaster .toast-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.3s;
}
.modular-toaster .toast-enter-done {
  transform: none;
}
.modular-toaster .toast-exit {
  opacity: 1;
  max-height: 1000px;
}
.modular-toaster .toast-exit-active {
  opacity: 0;
  max-height: 0;
  margin-bottom: 0 !important;
  overflow: hidden;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin-bottom 0.3s;
}

/* Variants */
.modular-toaster .modular-toast.toast-info {
  --toast-bg: #e9e9ff;
  --toast-border-color: #0d6efd;
  --toast-border-width: 0 0 0 .375rem;
  --toast-color: #0d6efd;
  --toast-icon-color: #0d6efd;
  --toast-close-button-color: #0d6efd;
  --toast-close-button-shadow-color: rgba(13, 110, 253, 0.25);
}
.modular-toaster .modular-toast.toast-success {
  --toast-bg: #e4f8f0;
  --toast-border-color: #198754;
  --toast-border-width: 0 0 0 .375rem;
  --toast-color: #198754;
  --toast-icon-color: #198754;
  --toast-close-button-color: #198754;
  --toast-close-button-shadow-color: rgba(25, 135, 84, 0.25);
}
.modular-toaster .modular-toast.toast-warning {
  --toast-bg: #fff2e2;
  --toast-border-color: #dda600;
  --toast-border-width: 0 0 0 .375rem;
  --toast-color: #dda600;
  --toast-icon-color: #dda600;
  --toast-close-button-color: #dda600;
  --toast-close-button-shadow-color: rgba(255, 193, 7, 0.25);
}
.modular-toaster .modular-toast.toast-error {
  --toast-bg: #ffe7e6;
  --toast-border-color: #dc3545;
  --toast-border-width: 0 0 0 .375rem;
  --toast-color: #dc3545;
  --toast-icon-color: #dc3545;
  --toast-close-button-color: #dc3545;
  --toast-close-button-shadow-color: rgba(220, 53, 69, 0.25);
}
.modular-toaster .modular-toast.toast-neutral {
  --toast-bg: #ffe7e6;
  --toast-border-color: #6c757d;
  --toast-border-width: 0 0 0 .375rem;
  --toast-color: #6c757d;
  --toast-icon-color: #6c757d;
  --toast-close-button-color: #6c757d;
  --toast-close-button-shadow-color: rgba(108, 117, 125, 0.25);
}

.button:not(.modular-button-icon) {
  padding: 0.375rem 0.75rem;
}

.button {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 0.375rem;
  color: #fff;
  background-color: #0d6efd;
  border: 1px solid #0d6efd;
  cursor: pointer;
}
.button.active {
  pointer-events: none;
  background-color: #0d6efd;
}
.button:disabled {
  pointer-events: none;
  opacity: 0.65;
}
.button:not(:disabled):focus {
  outline: 0;
  background-color: #0d6efd;
}
.button:not(:disabled):hover {
  color: #fff;
  background-color: #0d6efd;
}

.button-outline {
  padding: 0.375rem 0.75rem;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 0.375rem;
  color: #0d6efd;
  background-color: white;
  border: 1px solid #0d6efd;
  cursor: pointer;
}
.button-outline.active {
  pointer-events: none;
  background-color: #0d6efd;
}
.button-outline:disabled {
  pointer-events: none;
  opacity: 0.65;
}
.button-outline:not(:disabled):focus {
  outline: 0;
  background-color: #0d6efd;
}
.button-outline:not(:disabled):hover {
  color: #fff;
  background-color: #0d6efd;
}

/* .modular-button-icon {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 50%;
  color: #fff;
  background-color: #0d6efd;
}
.modular-button-icon.active {
  pointer-events: none;
}
.modular-button-icon:disabled {
  pointer-events: none;
  opacity: 0.65;
}
.modular-button-icon:not(:disabled):focus {
  outline: 0;
  background-color: #0d6efd;
}
.modular-button-icon:not(:disabled):hover {
  color: #fff;
  background-color: #0d6efd;
} */

/* .input {
  padding: 0.375rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
}
.input:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
} */

/* .select {
  appearance: none;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0.275rem;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
}
.select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
} */
.modular-advanced-select .mod-as-field.is-invalid:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.modular-advanced-select .mod-as-field.is-invalid {
  border-color: #dc3545;
}
.modular-editable-select .mod-es-select {
  padding-right: 2.25rem;
  /* padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 0.275rem;
  background-color: #fff; */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
}
/* .modular-editable-select .mod-es-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
} */

.mod-es-menu,
.mod-as-menu {
  background-color: #fff;
}

.modular-datatable table {
  --bs-table-bg: transparent;
}

.modular-datatable .dt-wrapper > table thead tr > *,
.modular-datatable .dt-wrapper > table tbody tr > *,
.modular-datatable .dt-wrapper > table tfoot tr > * {
  /* padding: 0.5rem;
  border-bottom: 1px solid #dee2e6; */
  vertical-align: middle;
}
.modular-datatable .dt-wrapper > table .dth-selector input:not(:checked),
.modular-datatable .dt-wrapper > table .dtr-selector input:not(:checked) {
  border-width: 1.5px;
  border-color: #495057;
}
.modular-datatable .dt-wrapper > table .dtr-selector .radio {
  --radio-border-color: #dee2e6;
  appearance: none;
  width: 1em;
  height: 1em;
  border: 1px solid var(--radio-border-color);
  border-radius: 50%;
  background-color: var(--radio-bg-color);
  background-image: var(--radio-bg-image);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.modular-datatable .dt-wrapper > table .dtr-selector .radio:checked {
  --radio-bg-color: #0d6efd;
  --radio-border-color: #0d6efd;
  --radio-bg-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e") ;
}
.modular-datatable .dt-wrapper > table .dtr-selector .radio:disabled, .modular-datatable .dt-wrapper > table .dtr-selector .radio.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.modular-datatable .dt-wrapper > table .dtr-selector .radio:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.modular-datatable .dtr-selector input[type="checkbox"],
.modular-datatable .dtr-selector input[type="radio"],
.modular-datatable .dth-selector input[type="checkbox"],
.modular-datatable .dth-selector input[type="radio"] {
    cursor: pointer;
}

.modular-modal-container {
    min-width: 360px;
}

.modular-modal-content {
  background-color: #fff;
  width: 25rem;
  border-radius: 0.25rem;
}

.modular-confirm .mod-cb-icon {
  color: #0d6efd;
}
.modular-confirm .mod-confirm-header {
  border-bottom: 1px solid #dee2e6;
}
.modular-confirm .mod-confirm-footer {
  border-top: 1px solid #dee2e6;
}

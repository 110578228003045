.tablaRecibos{
    position: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #eeee;
    border: 0.5px solid black;
}

.tablaRecibos > thead  tr  th { 
    min-height: 5vh;
}

.tablaRecibos > tbody {
    overflow-y: hidden;
    overflow-x: auto;
    background-color: white;
}

.tablaRecibos > tbody:hover {
    overflow: auto; /* fallback */
    overflow: overlay;
}

.tablaRecibos > tbody > tr >td, .tablaRecibos > thead > tr > th {
    min-height: 70px;
    max-height: 95px;
    width: 100%;
    margin: 0px;
    border-left: 0.5px solid black;
}

.tablaRecibos tr{
    display: flex;
}

.tablaRecibos > tbody > tr > td.textLeft {
    text-align: left;
}

.tablaRecibos > tbody > tr > td.textRight {
    text-align: right;
}

.tablaRecibos > tbody > tr > td > span.mr-45 {
    margin-right: 45%;
}

.tablaRecibos > tbody > tr > td > span.mr-30 {
    margin-right: 30%;
}

.tablaRecibos > tbody > tr > td > span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}

.tablaRecibos > thead > tr{
    padding: 0;
    min-height: 5vh;
    max-height: 10vh;
}


.tablaRecibos > tbody > tr{
    background-color: #eeee;
}

.tablaRecibos > tbody td,.tablaRecibos > thead{
    border-bottom: 0.5px solid black;
}

.tablaRecibos > tbody >tr:focus{
    box-shadow:0px 0px 10px lightgray;
}


.tablaRecibos > tbody >tr:hover{
    background-color: lightblue;
}

.tablaRecibos tr div{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.tablaRecibos th,
.tablaRecibos td {
    vertical-align: middle;
}

.tablaRecibos tr.selected > td,
.tablaRecibos tr.selected > th {
    background-color: #003770;
    color: #fff;
}
